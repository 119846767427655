@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'),
    url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'),
    url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'),
    url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'),
    url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-Bold'),
    url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'),
    url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: normal;
}
