@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  margin-left: 40px;
  background-color: #eee;
  color: #000000;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-top: 2px solid #ffffff;
  width: 100%;
  height: 70px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #ccc;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #efefef;
  overflow: auto;
  margin-left: 40px;
  width: 563px;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding: 18px;
}

@media only screen and (max-width: 375px) {
  .accordion__content {
    width: 100vw;
  }
  .accordion__text {
    font-size: 16px;
    text-align: left;
  }
}
